import Layout from '../components/Layout'
import Custom404Template from '../components/templates/404'

const Custom404 = ({ data }) => {
  return (
    <Layout data={data} noMargin removeAds>
      <Custom404Template />
    </Layout>
  )
}

export default Custom404
